<template>
  <transition name="slide-fade">
    <div 
      v-if="visible"
      class="custom-alert"
      :class="[typeClass]"
      @mouseover="pauseTimeout"
      @mouseleave="resumeTimeout"
    >
      <div class="alert-content">
        <strong>{{ title }}</strong>
        <p>{{ message }}</p>
      </div>
      <button class="close-btn" @click="hide">×</button>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      title: '',
      message: '',
      type: 'success',
      timeoutId: null
    }
  },
  computed: {
    typeClass () {
      return `alert-${this.type}`
    }
  },
  created () {
    this.$alertBus.$on('show', this.show)
  },
  beforeDestroy () {
    this.$alertBus.$off('show', this.show)
  },
  methods: {
    show (options) {
      this.title = options.title
      this.message = options.message
      this.type = options.type || 'success'
      this.visible = true
      
      this.timeoutId = setTimeout(() => {
        this.hide()
      }, 5000)
    },
    hide () {
      this.visible = false
      clearTimeout(this.timeoutId)
    },
    pauseTimeout () {
      clearTimeout(this.timeoutId)
    },
    resumeTimeout () {
      this.timeoutId = setTimeout(() => {
        this.hide()
      }, 5000)
    }
  }
}
</script>

<style scoped>
.custom-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 1rem;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 9999;
}

.alert-success {
  background: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.alert-danger {
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding-left: 1rem;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
