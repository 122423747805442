<template>
   <b-container fluid class="content-wrapper">
    <div class="qr-monedero-add-balance">
      <h1>Añadir Saldo a QR Monedero</h1>
      <b-row>
        <b-col lg="6">
          <b-form @submit.prevent="submitForm">
            <b-form-group
              id="qr-code-group"
              label="Seleccionar QR Monedero:"
              label-for="qr-code"
            >
              <b-input-group>
                <b-form-input
                  id="qr-code"
                  v-model="qrFilter"
                  type="text"
                  placeholder="Buscar por código o nombre..."
                  autocomplete="off"
                  @input="filterQrMonederos"
                  @focus="showSuggestions = true"
                ></b-form-input>
                
                <div v-if="showSuggestions && filteredQrMonederos.length > 0" class="autocomplete-suggestions">
                  <div 
                    v-for="qr in filteredQrMonederos" 
                    :key="qr.id" 
                    class="autocomplete-suggestion"
                    @click="selectQrMonedero(qr)"
                  >
                    <div class="suggestion-name">{{ qr.name }}</div>
                    <div class="suggestion-details">
                      <span class="suggestion-code">{{ qr.qr_code }}</span>
                      <span class="suggestion-balance">{{ (qr.balance / 100).toFixed(2) }}€</span>
                    </div>
                  </div>
                </div>
              </b-input-group>
              
              <div v-if="selectedQr" class="selected-qr-info mt-2">
                <strong>QR Monedero seleccionado:</strong> {{ selectedQr.name }} - {{ selectedQr.qr_code }} 
                <span class="badge badge-info">{{ (selectedQr.balance / 100).toFixed(2) }}€</span>
              </div>
            </b-form-group>

            <b-form-group
              id="amount-group"
              label="Importe (€):"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="form.amount"
                type="number"
                min="0"
                step="0.01"
                placeholder="0.00"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="promo-amount-group"
              label="Importe Promocional (€):"
              label-for="promo-amount"
            >
              <b-form-input
                id="promo-amount"
                v-model="form.promo_amount"
                type="number"
                min="0"
                step="0.01"
                placeholder="0.00"
              ></b-form-input>
            </b-form-group>
         
            <b-button type="submit" variant="primary" :disabled="loading">
              <b-spinner small v-if="loading"></b-spinner>
              Añadir Saldo
            </b-button>
          </b-form>   
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AddQrMonederoBalance',
  data () {
    return {
      form: {
        qr_code: '',
        amount: 0,
        promo_amount: 0,
        station_id: null,
        machine: ''
      },
      qrMonederos: [],
      filteredQrMonederos: [],
      qrFilter: '',
      selectedQr: null,
      showSuggestions: false,
      loading: false,
      loadingQrs: false,
    }
  },
  created () {
    // Get user's station and machine from localStorage or Vuex store
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo && userInfo.stations && userInfo.stations.length > 0) {
      this.form.station_id = userInfo.stations[0].id
    }
    
    this.form.machine = '0001'

    this.fetchQrMonederos()
    
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    fetchQrMonederos () {
      this.loadingQrs = true
      axios.get('/api/qr-monedero/get-all-by-user')
        .then(response => {
          if (response.data.success) {
            this.qrMonederos = response.data.data
            this.filteredQrMonederos = [...this.qrMonederos]
          } else {
            console.error('Error fetching QR monederos:', response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching QR monederos:', error)
        })
        .finally(() => {
          this.loadingQrs = false
        })
    },
    filterQrMonederos () {
      this.showSuggestions = true
      
      if (!this.qrFilter.trim()) {
        this.filteredQrMonederos = [...this.qrMonederos]
        return
      }
      
      const filter = this.qrFilter.toLowerCase()
      this.filteredQrMonederos = this.qrMonederos.filter(qr => 
        qr.name.toLowerCase().includes(filter) || 
        qr.qr_code.toLowerCase().includes(filter)
      )
    },
    selectQrMonedero (qr) {
      this.selectedQr = qr
      this.form.qr_code = qr.qr_code
      this.qrFilter = qr.qr_code
      this.showSuggestions = false
    },
    handleClickOutside (event) {
      const autocomplete = this.$el.querySelector('.autocomplete-suggestions')
      const input = this.$el.querySelector('#qr-code')
      
      if (autocomplete && !autocomplete.contains(event.target) && !input.contains(event.target)) {
        this.showSuggestions = false
      }
    },
    submitForm () {
      this.loading = true
     
      const apiData = {
        ...this.form,
        amount: Math.round(parseFloat(this.form.amount) * 100),
        promo_amount: Math.round(parseFloat(this.form.promo_amount || 0) * 100)
      }
      
      axios.post('/api/qr-monedero/add-balance', apiData)
        .then(response => {
          this.showSuccess(`Saldo añadido correctamente. Nuevo saldo: ${response.data.new_balance / 100}€`)
          
          this.form.amount = 0
          this.form.promo_amount = 0
          
          this.fetchQrMonederos()
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          if (error.response && error.response.status === 400) {
            this.showError(error.response.data.message)
          } else {
            this.showError('Error al añadir saldo')
          }
          console.error('Error adding balance:', error)
        })
    },
    showSuccess (message) {
      this.$alert.success(message)
    },
    showError (message) {
      this.$alert.error(message)
    },
    showAlert (message) {
      this.$alert.warning(message)
    },
  }
}
</script>

<style scoped>
.qr-monedero-add-balance {
  padding: 2rem;
}
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.autocomplete-suggestions {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  z-index: 1000;
  margin-top: 2px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
.autocomplete-suggestion {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}
.autocomplete-suggestion:hover {
  background-color: #f8f9fa;
}
.suggestion-name {
  font-weight: bold;
}
.suggestion-details {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #6c757d;
  margin-top: 3px;
}
.selected-qr-info {
  padding: 8px 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #e9ecef;
}
</style>
