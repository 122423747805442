import CustomAlert from '@/components/CustomAlert.vue'

export default {
  install (Vue) {
    // Create a new Vue instance to serve as an event bus
    const alertBus = new Vue()
    
    // Add the alert instance to the Vue prototype
    Vue.prototype.$alert = {
      success (message) {
        alertBus.$emit('show', {
          title: '',
          message,
          type: 'success'
        })
      },
      error (message) {
        alertBus.$emit('show', {
          title: 'Error',
          message,
          type: 'danger'
        })
      }
    }

    // Register the CustomAlert component globally
    Vue.component('custom-alert', CustomAlert)

    // Add the alert bus to prototype so the component can listen to it
    Vue.prototype.$alertBus = alertBus
  }
}
